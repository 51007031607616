import React, {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next';
import BreadCrumbs from "../../components/breadcrumbs/BreadCrumbs";
import {jumpToTop} from "../../helper/util";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";
import {Icon} from "semantic-ui-react";


const handleClick = (index, activeIndex, setActiveIndex) => {
    let activeIndexes = activeIndex;

    if (activeIndex.includes(index)) {
        activeIndexes[index] = null;
        setActiveIndex(activeIndexes);
    } else {
        activeIndexes[index] = index;
        setActiveIndex(activeIndexes);
    }
};

function makeNewFaq(faqs, activeIndex, setActiveIndex) {
    return faqs.map((faq, index) => (
        <div itemScope
             itemProp="mainEntity"
             itemType="https://schema.org/Question"
             key={'faq' + index}>
            <div itemProp={'name'}
                 className={activeIndex.includes(index) ? 'title active' : 'title'}
                 onClick={() => handleClick(index, activeIndex, setActiveIndex)}
                 key={index}>
                <h2><i aria-hidden={'true'} className={'dropdown icon'}/>{faq.title}</h2>
            </div>
            <div itemScope
                 itemProp="acceptedAnswer"
                 itemType="https://schema.org/Answer"
                 className={activeIndex.includes(index) ? 'content active' : 'content'}>
                <p itemProp="text">
                    {faq.text}
                </p>
            </div>
        </div>));
}

const AmbulantFAQPage = (props) => {
    const {i18n} = props;

    const [allFAQs, setAllFAQs] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(_ => {
        //TODO: get Ambulant FAQs
        if (typeof window !== "undefined") {
            jumpToTop(window);
        }
    }, []);

    return (
        <div className={'grid ui'} style={{paddingTop: '300px', paddingBottom:'2rem'}}>
        <div style={{display: 'none'}}>
            <BreadCrumbs breadCrumbData={[{title: 'Ambulant-Faq', url: 'ambulant/faq'}]}/>
        </div>
        <div className={'row centered page-content-wrapper'}>
            <div className={'twelve wide column'}>
                <h1 style={{marginBottom: '20px'}}>
                    Fragen und Antworten.
                </h1>
            </div>
            <div className={'sixteen wide computer column'}>
                {allFAQs.length !== 0 ? (<div
                    className={'accordion ui fluid'}
                    itemScope
                    itemType="https://schema.org/FAQPage"
                >
                    {makeNewFaq(allFAQs, activeIndex, setActiveIndex)}
                </div>) : <div>
                    <Message info style={{textAlign: "center"}}>
                        <Message.Header>{i18n.t("faq:faq.faq")}</Message.Header>
                        <p>{i18n.t("faq:faq.no_faq")}<Icon name={"frown outline"}/></p>
                    </Message>
                </div>}
            </div>
        </div>
    </div>);

}

export default withTranslation(['faq'])(AmbulantFAQPage);


